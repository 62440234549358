/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import config from './config.json';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router';
import history from 'utils/history';
import combinedReducers from './reducers';
import 'createNonce.js';
import request from '@edgeguideab/client-request';
import globalActions from 'actions/global';
import sessionActions from 'actions/session';
import { browserLocale } from 'utils/language';
import NavigationContainer from 'components/layout/navigation/NavigationContainer';
import LanguageContainer from 'components/layout/languagePicker/LanguageContainer';
import ErrorBoundary from 'components/global/ErrorBoundary';
import ErrorView from 'components/views/ErrorView';
import { unregister } from './registerServiceWorker';
import routesFactory from 'routes/index';
import moment from 'moment';
import urls from 'utils/urls.json';
import 'index.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { Helmet } from 'react-helmet';

const devToolsMiddleware =
  window.devToolsExtension && process.env.NODE_ENV === 'development'
    ? window.devToolsExtension()
    : (f) => f;

const middlewares =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunk), devToolsMiddleware)
    : compose(applyMiddleware(thunk), devToolsMiddleware);

const store = createStore(combinedReducers, {}, middlewares);

unregister();

store.dispatch(globalActions.loadEwmFeatures() as any);

const checkSession = async ({ ewmFeature } = { ewmFeature: false }) => {
  let state = store.getState();
  if (!state.session.get('loggedIn')) {
    await store.dispatch(sessionActions.checkSession() as any);
  }
  state = store.getState();
  if (!state.global.get('loadedConfig')) {
    store.dispatch(globalActions.loadEwmConfig() as any);
  }
  if (ewmFeature) {
    state = store.getState();
    const ewmFeatures = state.global.get('ewmFeatures');
    const userSession = state.session.get('user');
    const hideEagleEye = () =>
      !userSession.get('organizationIsPro') &&
      userSession.get('role') !== 'superUser';
    if (!ewmFeatures.includes(ewmFeature) || !userSession || hideEagleEye()) {
      history.replace(urls.configurationsList);
    }
  }
};

const goToLogin = () => {
  store.dispatch({ type: 'SESSION_LOGOUT_DONE' });
  history.replace(urls.login);
};
let sessionTimeout = -1;

request.bind('.*', ({ url, options }) => {
  options.headers = options.headers || {};
  options.headers['Accept'] = options.headers['Accept'] || 'application/json';
  return { url, options };
});
request.bind('^/api/', ({ url, options }) => {
  options.headers['X-CSRF-Token'] = window.localStorage.getItem('csrfToken');
  return { url, options };
});
request.bindResponseMiddleware(function (oReq) {
  if (oReq.status === 401 && oReq.response === 'no_session') {
    return goToLogin();
  }
  const expiryTime = oReq.getResponseHeader('x-session-expires');
  if (expiryTime) {
    //@ts-ignore
    const timeout = moment(expiryTime) - moment();
    if (timeout <= 0) {
      return;
    }
    clearTimeout(sessionTimeout);
    sessionTimeout = window.setTimeout(goToLogin, timeout);
  }
});

const routes = routesFactory(checkSession);
let localStorageLanguage;
try {
  localStorageLanguage = localStorage.getItem('language');
} catch (e) {
  // default to swedish
}
const currentLanguageCode = localStorageLanguage || browserLocale();
getLanguageAndRender(currentLanguageCode).catch(console.error);

async function getLanguageAndRender(languageCode) {
  let response;
  try {
    response = await request.get(`${config.apiServer}/language`, {
      query: { languageCode }
    });
  } catch (err) {
    if (err.error === 'errors.noSuchLanguagePack') {
      await getLanguageAndRender(config.defaultLanguage);
    } else {
      console.error(err);
    }
    ReactDOM.render(
      <ErrorView error={err.body} />,
      document.getElementById('root')
    );
    return;
  }
  if (response) {
    window.LanguageKeys = response.body;
  }
  if (languageCode !== config.defaultLanguage && !localStorageLanguage) {
    store.dispatch({
      type: 'SET_FLAG_LANGUAGE',
      payload: languageCode
    });
  }
  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY as any;
  };

  document.addEventListener('scroll', storeScroll);
  storeScroll();
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Router history={history}>
            <Helmet>
              <script
                src='https://kit.fontawesome.com/ad9a19cd5e.js'
                crossOrigin='anonymous'
              ></script>
            </Helmet>
            <div>
              <LanguageContainer />
              <NavigationContainer />
              <Route path='/'>{routes}</Route>
            </div>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
  );
}
