/** @format */

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import config from 'config.json';
import NavigationBar from 'components/layout/navigation/NavigationBar';
import sessionActions from 'actions/session';
import globalActions from 'actions/global';
const mapStateToProps = (state, ownProps) => {
  const userRole = state.session.getIn(['user', 'role']);
  const acl = state.session.getIn(['user', 'acl']) || List();
  const userReadAcl = acl.getIn(['user', 'read']) || List();
  const organizationReadAcl = acl.getIn(['organization', 'read']) || List();
  const simCardReadAcl = acl.getIn(['simCard', 'read']) || List();
  const firmwareReadAcl = acl.getIn(['firmwares', 'read']) || List();
  const dalmAccessAcl = acl.getIn(['configuration', 'dalmAccess'], List());
  const thingsConnectionsReadAcl =
    acl.getIn(['thingsConnections', 'read']) || List();
  const showUsers = userReadAcl.includes('any');
  const showAllOrganizations = organizationReadAcl.includes('any');
  const showOwnOrganization = organizationReadAcl.includes('organization');
  const showSimCards =
    simCardReadAcl.includes('any') || simCardReadAcl.includes('organization');
  const showFirmwares =
    userRole === 'superUser' || firmwareReadAcl.includes('any');
  const showStartPage = userRole !== undefined && userRole !== 'endCustomer';
  const showConfigurations = userRole !== 'endCustomer'; // End customers can technically list configurations, but should be hidden in interface.
  const showManuals = userRole !== 'endCustomer';
  const showNews = userRole !== 'endCustomer';
  const showVpn = state.global.get('ewmFeatures').includes('dualtechVpn');
  const expandedNavigationItems =
    state.global.getIn(['navigation', 'expandedItems']) || List();
  const showThings =
    state.global.get('ewmFeatures').includes('things') &&
    (dalmAccessAcl.includes('organization') || dalmAccessAcl.includes('any'));
  const showThingsConnections =
    state.global.get('ewmFeatures').includes('thingsConnections') &&
    thingsConnectionsReadAcl.size > 0;
  const showDocuments = state.global.get('ewmFeatures').includes('documents');
  const organizationId = state.session.getIn(['user', 'organizationId']);
  let organizationLogo = null;

  if (state.session.getIn(['user', 'organizationLogo']) && organizationId) {
    organizationLogo = `${config.apiServer}/organizations/${organizationId}/logo`;
  }

  return {
    ewmFeatures: state.global.get('ewmFeatures'),
    loggedIn: state.session.get('loggedIn'),
    browserPath: ownProps.location.pathname,
    showHamburgerMenu: state.global.getIn(['navigation', 'showHamburgerMenu']),
    isSuperUser: state.session.getIn(['user', 'role']) === 'superUser',
    isPro: state.session.getIn(['user', 'organizationIsPro']),
    organizationLogo,
    checkedSession: state.session.get('checkedSession'),
    isChecking: state.session.get('isChecking'),
    language: state.global.get('language'), // to force re render on language change.
    demoUser: state.session.getIn(['user', 'username']) === 'demo',
    expandedNavigationItems,
    showUsers,
    showAllOrganizations,
    showOwnOrganization,
    showFirmwares,
    showStartPage,
    showConfigurations,
    showSimCards,
    showManuals,
    showNews,
    showVpn,
    showThings,
    showThingsConnections,
    showDocuments
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addExpandedItem(expandedId) {
      dispatch({ type: 'ADD_EXPANDED_ITEM', payload: expandedId });
    },
    openHamburgerMenu() {
      dispatch({ type: 'OPEN_HAMBURGER_MENU' });
    },
    closeHamburgerMenu(event) {
      if (event && event.target && event.target.id === 'expandable') return;
      dispatch({ type: 'CLOSE_HAMBURGER_MENU' });
    },
    closeExpandedMenus() {
      dispatch({ type: 'CLOSE_EXPANDED_NAVIGATION_ITEM' });
    },
    onExpand(path) {
      dispatch({
        type: 'TOGGLE_EXPANDABLE_NAVIGATION_ITEM',
        payload: { path }
      });
    },
    logout() {
      dispatch(sessionActions.logout());
    },
    showAboutModal() {
      dispatch(globalActions.getApplicationVersion());
      dispatch({ type: 'CHANGE_ABOUT_MODAL_STATE' });
    }
  };
};

const NavigationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar);

export default withRouter(NavigationContainer);
