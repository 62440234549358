/** @format */

import { fromJS, Map } from 'immutable';

const INITIAL_STATE = fromJS({
  sendInvoice: true,
  isShowing: false,
  product: null,
  error: Map()
});

export default function changeProductReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_INVOICING_CHECKBOX': {
      return state
        .set('sendInvoice', !state.get('sendInvoice'))
    }
    case 'CHANGE_PRODUCT': {
      return state.set('product', action.payload);
    }
    case 'OPEN_CHANGE_PRODUCT_MODAL': {
      return state.set('isShowing', true);
    }
    case 'HIDE_CHANGE_PRODUCT_MODAL': {
      return state.set('isShowing', false).set('product', null);
    }
    case 'CHANGE_PRODUCT_START': {
      return state.set('error', Map());
    }
    case 'CHANGE_PRODUCT_FAILED': {
      const error = action.payload.msg;
      return state.set('error', fromJS(error));
    }
    default: {
      return state;
    }
  }
}
