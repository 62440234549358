/** @format */

import React from 'react';
import { languages, slogan } from 'config';

export default class LanguageBar extends React.Component {
  componentDidMount() {
    this.props.pollMaintenanceText();
    this.poll();
  }
  poll() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.pollMaintenanceText();
      this.poll();
    }, 60000);
  }
  render() {
    const { chosenLanguage, changeLanguage, maintenanceText } = this.props;
    return (
      <div className={`language-bar ${!maintenanceText && 'mobile-hide'}`}>
        <div className='slogan'>{slogan}</div>
        {maintenanceText && (
          <div className='maintenanceText'>
            <span>{maintenanceText}</span>
          </div>
        )}
        <div className='flags'>
          {languages.map((language, i) => (
            <a
              key={i}
              className={`flag-wrapper ${
                chosenLanguage === language.languageCode ? 'selected' : ''
              }`}
              onClick={() => changeLanguage(language.languageCode)}
            >
              <div className={`flag flag-icon flag-icon-${language.flag}`} />
            </a>
          ))}
        </div>
      </div>
    );
  }
}
