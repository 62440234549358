/** @format */

import { translate } from 'utils/language';
import urls from 'utils/urls';

export const getPaths = ({
  loggedIn,
  ewmFeatures,
  logout,
  showUsers,
  showAllOrganizations,
  showOwnOrganization,
  showFirmwares,
  showStartPage,
  showConfigurations,
  showSimCards,
  showManuals,
  showNews,
  showVpn,
  showThings,
  showThingsConnections,
  demoUser,
  isSuperUser,
  isPro,
  showDocuments,
  onExpand,
  showAboutModal
}) => [
  {
    pathname: urls.login,
    name: translate('login'),
    alwaysHamburger: true,
    hidden: loggedIn
  },
  {
    pathname: '',
    name: translate('main.logout'),
    onClick: logout,
    alwaysHamburger: true,
    hidden: !loggedIn
  },
  {
    pathname: '',
    name: translate('main.about'),
    onClick: showAboutModal,
    alwaysHamburger: true
  },
  {
    pathname: urls.termsAndConditions,
    name: translate('terms.and.conditions'),
    alwaysHamburger: true,
    hidden: !ewmFeatures.includes('termsAndConditions')
  },
  {
    pathname: urls.mobileLanguage,
    name: translate('menu.option.language'),
    alwaysHamburger: true,
    onlyMobile: true
  },
  {
    pathname: urls.allNews,
    name: translate('news'),
    alwaysHamburger: true,
    hidden: !loggedIn || !showNews || !ewmFeatures.includes('news')
  },
  {
    pathname: urls.manuals,
    name: translate('manuals'),
    alwaysHamburger: true,
    hidden: !loggedIn || !showManuals || !ewmFeatures.includes('manuals')
  },
  {
    pathname: urls.home,
    name: translate('home'),
    alwaysHamburger: false,
    hidden: loggedIn
  },
  {
    pathname: urls.documents,
    name: translate('documents'),
    alwaysHamburger: false,
    hidden: !loggedIn || !showDocuments
  },
  {
    pathname: urls.firmware,
    name: translate('firmwares'),
    alwaysHamburger: true,
    hidden: !showFirmwares
  },
  {
    pathname: urls.kernel,
    name: translate('kernels'),
    alwaysHamburger: true,
    hidden: !showFirmwares
  },
  {
    pathname: urls.ownSimCardList,
    name: translate('sim.header'),
    alwaysHamburger: false,
    hidden: !loggedIn || !ewmFeatures.includes('sim') || !showSimCards
  },
  {
    pathname: urls.vpn,
    name: translate('menu.vpn.temporary'),
    alwaysHamburger: false,
    isExpandItem: true,
    parentId: 'vpn',
    hidden: !loggedIn || !showVpn
  },
  {
    pathname: urls.vpnCustom,
    name: translate('menu.vpn.custom'),
    alwaysHamburger: false,
    isExpandItem: true,
    parentId: 'vpn',
    hidden: !loggedIn || !showVpn
  },
  {
    pathname: ewmFeatures.includes('customVpn') ? '' : urls.vpn,
    name: translate('vpn'),
    alwaysHamburger: false,
    expandable: ewmFeatures.includes('customVpn'),
    id: 'vpn',
    onClick: ewmFeatures.includes('customVpn') && onExpand,
    hidden: !loggedIn || !showVpn
  },
  {
    pathname:
      showThings && showThingsConnections
        ? ''
        : showThings
        ? urls.things
        : showThingsConnections
        ? urls.thingsConnections
        : '',
    name: translate('things'),
    alwaysHamburger: false,
    id: 'things',
    expandable: showThings && showThingsConnections,
    onClick: showThings && showThingsConnections ? onExpand : null,
    hidden: !loggedIn || !(showThings || showThingsConnections)
  },
  ...(showThings && showThingsConnections
    ? [
        {
          pathname: urls.things,
          name: translate('things'),
          alwaysHamburger: false,
          isExpandItem: true,
          parentId: 'things',
          hidden: !loggedIn || !showThings
        },
        {
          pathname: urls.thingsConnections,
          name: translate('mqtt.status'),
          alwaysHamburger: false,
          isExpandItem: true,
          parentId: 'things',
          hidden: !loggedIn || !showThingsConnections
        }
      ]
    : []),
  {
    pathname: urls.mobileModemFirmware,
    name: translate('mobile.modem.firmwares'),
    alwaysHamburger: true,
    hidden: !showFirmwares
  },
  {
    pathname: urls.organizationAdmin,
    name: translate('organizations'),
    alwaysHamburger: false,
    hidden: !showAllOrganizations
  },
  {
    pathname: urls.user,
    name: translate('user.my.account'),
    alwaysHamburger: true,
    hidden: !loggedIn || demoUser
  },
  {
    pathname: urls.userList,
    name: translate('users'),
    alwaysHamburger: false,
    hidden: !showUsers
  },
  {
    pathname: urls.ownOrganization,
    name: translate('organization'),
    alwaysHamburger: true,
    hidden: !showOwnOrganization
  },
  {
    pathname: urls.serviceLogs,
    name: translate('serviceLogs'),
    alwaysHamburger: false,
    hidden: !isSuperUser
  },
  {
    pathname: urls.configurationsList,
    name: translate('configurations'),
    alwaysHamburger: false,
    hidden: !loggedIn || !showConfigurations
  },
  {
    pathname: urls.eagleEyeList,
    name: translate('eagleeye'),
    alwaysHamburger: false,
    hidden: !ewmFeatures.includes('eagleEye') || !isPro || isSuperUser
  },
  {
    pathname: urls.startPage,
    name: translate('start'),
    alwaysHamburger: false,
    hidden: !loggedIn || !showStartPage || !ewmFeatures.includes('startPage')
  }
];
