/** @format */

import { fromJS, Map } from 'immutable';
import { formatReceiver } from 'actions/configuration/utils';

const INITIAL_PATH_SETTINGS = {
  primaryReceiverIp: '',
  primaryReceiverPort: '',
  secondaryReceiverIp: '',
  secondaryReceiverPort: '',
  primaryethernetReceiverIp: '',
  primaryethernetReceiverPort: '',
  primarymobileReceiverIp: '',
  primarymobileReceiverPort: '',
  secondaryethernetReceiverIp: '',
  secondaryethernetReceiverPort: '',
  secondarymobileReceiverIp: '',
  secondarymobileReceiverPort: ''
};

const INITIAL_ALARM_CENTER_JSON = {
  name: '',
  pollingInterval: 'select',
  pollingScheme: 'standard',
  samePathSettings: true,
  ...INITIAL_PATH_SETTINGS,
  hasDc09: false,
  dc09: {},
  v4: {},
  encrypted: true,
  encryptedOptional: true,
  showExtendedBody: false,
  position: 1,
  copiedFromPredefinedAlarmCenter: '',
  events: [],
  communicationPath: 'select',
  alarmClass: 'select',
  eventProtocol: 'select',
  lockedProtocol: false
};

const INITIAL_STATE = fromJS([]);

const serialPortPositionMap = {
  COM1: 49,
  COM2: 50,
  'COM3 (5V)': 51,
  RS485: 52
};

export default function alarmCentersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, params, name } = action.payload;
      let { value } = action.payload;
      if (index !== undefined && type !== undefined) {
        if (name === 'communicationPath') {
          state = state.set(index, updatePathSettings(state.get(index), value));
        }
        if (name === 'samePathSettings') {
          state = state.set(
            index,
            toggleSamePathSettings(state.get(index), value)
          );
        }

        if (name.includes('v4.') || name.includes('dc09.')) {
          const layers = name.split('.');
          return state.setIn([index, layers[0], layers[1]], value);
        } else if (name === 'eventProtocol') {
          if (value === 'select')
            return state
              .setIn([index, name], value)
              .setIn([index, 'eventFormat'], 'select');
          const hasDc09 = value.includes('dc09');
          const eventFormat = value.split('.')[1];
          const predefinedAlarmCenters =
            action.payload.params.predefinedAlarmCenters;
          const defaultAlarmCenter = predefinedAlarmCenters.find(
            (alarmCenter) => alarmCenter.get('name') === 'iprec.predef.dc09'
          );
          let alarmCenter;
          if (
            state.getIn([index, 'copiedFromPredefinedAlarmCenter']) === 'other'
          ) {
            alarmCenter = predefinedAlarmCenters.find(
              (predefined) => predefined.get('name') === 'iprec.predef.dc09'
            );
          } else {
            alarmCenter = predefinedAlarmCenters.find(
              (predefined) =>
                predefined.get('name') ===
                state.getIn([index, 'copiedFromPredefinedAlarmCenter'])
            );
          }
          const events = getEventCodes(
            state.getIn([index, 'events']),
            eventFormat,
            alarmCenter,
            defaultAlarmCenter
          );
          return state
            .setIn([index, name], value)
            .setIn([index, 'hasDc09'], hasDc09)
            .setIn([index, 'eventFormat'], eventFormat)
            .setIn([index, 'events'], events);
        } else if (params && params.events) {
          return state.setIn([index, 'events', params.index, name], value);
        } else if (type === 'alarmCenters') {
          if (name.includes('Receiver')) {
            value = value.replace(/\s/g, '');
          } else if (name === 'copiedFromPredefinedAlarmCenter' && value) {
            const arcEvents = state.getIn([index, 'events']);
            state = changeAlarmCenter(state, action);
            const predefinedAlarmCenters = params.predefinedAlarmCenters;
            const defaultAlarmCenter = predefinedAlarmCenters.find(
              (alarmCenter) => alarmCenter.get('name') === 'iprec.predef.dc09'
            );
            let alarmCenter;
            if (value === 'other') {
              alarmCenter = predefinedAlarmCenters.find(
                (predefined) => predefined.get('name') === 'iprec.predef.dc09'
              );
            } else {
              alarmCenter = predefinedAlarmCenters.find(
                (predefined) => predefined.get('name') === value
              );
            }
            state = state.setIn([index, 'lockedProtocol'], value !== 'other');
            const events = getEventCodes(
              arcEvents,
              state.getIn([index, 'eventFormat']),
              alarmCenter,
              defaultAlarmCenter
            );
            state = state.setIn([index, 'events'], events);
            state = state.setIn([index, 'eventProtocol'], 'select');
          }
          value = value === 'false' ? false : value === 'true' ? true : value;
          return state.setIn([index, name], value);
        } else if (type === 'inputs' && name === 'position') {
          if (!value) {
            return removeEvent(state, parseInt(params.oldPosition, 10));
          }
          const predefinedAlarmCenters = params.predefinedAlarmCenters;
          return addEvent(
            state,
            params.oldPosition ? parseInt(params.oldPosition, 10) : null,
            parseInt(value, 10),
            predefinedAlarmCenters
          );
        } else if (
          type === 'line' &&
          (name === 'lineSupervision' || name === 'alarmProtocol')
        ) {
          const { alarmProtocol, lineSupervision, predefinedAlarmCenters } =
            params;
          return lineEvent(
            state,
            name,
            value,
            alarmProtocol,
            lineSupervision,
            predefinedAlarmCenters
          );
        } else if (
          type === 'serialPorts' &&
          (name === 'position' || name.includes('type'))
        ) {
          const position = serialPortPositionMap[params.oldPosition];
          state = removeEvent(state, position);
          if (name.includes('type') && value.get('serialError')) {
            state = addEvent(
              state,
              null,
              position,
              params.predefinedAlarmCenters,
              value.get('eventCode')
            );
          }
        }
      } else if (
        (type === 'ipv4' &&
          name !== 'respondToWanPing' &&
          value.includes('disabled') &&
          params.ipv6.includes('none')) ||
        (type === 'ipv6' &&
          value.includes('none') &&
          params.ipv4.includes('disabled'))
      ) {
        const alarmCenters = state;
        alarmCenters.forEach((alarmCenter, i) => {
          if (
            alarmCenter &&
            alarmCenter.get('communicationPath') !== 'mobile'
          ) {
            state = state.setIn([i, 'communicationPath'], 'select');
          }
        });
      } else if (name === 'product' && value === 'nena') {
        const alarmCenters = state;
        alarmCenters.forEach((alarmCenter, i) => {
          if (alarmCenter) {
            state = state
              .setIn([i, 'communicationPath'], 'select')
              .setIn([i, 'pollingInterval'], 'select')
              .setIn([i, 'pollingScheme'], 'standard')
              .setIn([i, 'alarmClass'], null);
          }
        });
      }
      if (name === 'product' || name === 'oem') {
        const alarmCenters = state;
        alarmCenters.forEach((alarmCenter) => {
          if (alarmCenter) {
            const arcEvents = alarmCenter.get('events');
            const ipProtocolsEvents = arcEvents.filter(
              (event) =>
                57 < event.get('position') && event.get('position') < 62
            );
            ipProtocolsEvents.forEach((event) => {
              state = removeEvent(state, parseInt(event.get('position'), 10));
            });
          }
        });
      }
      if (type === 'bacnet' && name === 'lanPosition') {
        state = removeEvent(state, 63);
        if (value) {
          state = addEvent(state, null, 63, params.predefinedAlarmCenters);
        }
      }
      return state;
    }
    case 'EDIT_AUTHENTICATION_CODE_DONE': {
      const alarmCenter = state.find(
        (arc) => arc.get('id') === action.payload.alarmCenterId
      );
      if (!alarmCenter) {
        return state;
      }
      return state.setIn(
        [alarmCenter.get('position') - 1, 'v4', 'authenticationCode'],
        action.payload.newAuthenticationCode
      );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_ALARM_CENTER': {
      const configuration = action.payload.configuration;
      const alarmCenters = state.toJS();
      const alarmCenter = INITIAL_ALARM_CENTER_JSON;
      alarmCenter.events = getEvents(configuration);
      const positions = [1, 2, 3].filter(
        (pos) => !alarmCenters.some((arc) => arc.position === pos)
      );
      alarmCenter.position = positions[0];
      alarmCenters.push(alarmCenter);
      return fromJS(alarmCenters);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_ALARM_CENTER': {
      return state.delete(action.payload.index);
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_INPUT': {
      if (action.payload.position) {
        return removeEvent(state, parseInt(action.payload.position, 10));
      }
      return state;
    }
    case 'SECURITY_ROUTER_CONFIGURATION_ADD_IP_PROTOCOLS_DC09': {
      const { params } = action.payload;
      const predefinedAlarmCenters = params.predefinedAlarmCenters;
      return addEvent(
        state,
        null,
        parseInt(params.position, 10) + 57,
        predefinedAlarmCenters
      );
    }
    case 'SECURITY_ROUTER_CONFIGURATION_REMOVE_IP_PROTOCOLS_DC09': {
      const { position } = action.payload;
      state = removeEvent(state, parseInt(position, 10) + 57);
      state.forEach((alarmCenter, ai) => {
        const arcEvents = alarmCenter.get('events');
        const ipProtocolsEvents = arcEvents.filter(
          (event) => 57 < event.get('position') && event.get('position') < 62
        );
        ipProtocolsEvents.forEach((event, i) => {
          const eventIndex = alarmCenter
            .get('events')
            .findIndex((e) => e.get('position') === event.get('position'));
          state = state.setIn([ai, 'events', eventIndex, 'position'], i + 58);
        });
      });
      return state;
    }
    case 'COPIED_SECURITY_ROUTER_CONFIGURATION_DONE': {
      const alarmCenters = state.toJS();
      alarmCenters.forEach((alarmCenter) => {
        alarmCenter.commissionedAt = null;
        if (alarmCenter.hasDc09) {
          alarmCenter.dc09.encryptionKey = '';
          alarmCenter.dc09.accountNumber = '';
        } else {
          alarmCenter.v4.transmitterCode = '';
          alarmCenter.v4.authenticationCode = '';
        }
      });
      return fromJS(alarmCenters);
    }
    default: {
      return state;
    }
  }
}
const errorInputPositionMap = {
  'errorInputType.sabotage': 17,
  'errorInputType.battery': 18,
  'errorInputType.power': 19,
  'errorInputType.line': 20,
  'errorInputType.ethernet': 21,
  'errorInputType.gprs': 22,
  'errorInputType.exp': 23
};
function getEvents(configuration) {
  const events = [];
  const inputs = configuration.getIn(['inputs', 'data']);
  inputs.forEach((input) => {
    events.push({
      position: input.get('position'),
      active: true,
      editable: true
    });
  });
  const errorInputs = configuration.get('errorInputs');
  errorInputs.forEach((errorInput) => {
    events.push({
      position: errorInputPositionMap[errorInput.get('errorInputType')],
      active: true,
      editable: true
    });
  });
  const serialPorts = configuration.getIn(['serialPorts', 'data']);
  serialPorts
    .filter((serialPort) => serialPort.get('serialError'))
    .forEach((serialPort) => {
      events.push({
        position: serialPortPositionMap[serialPort.get('position')],
        active: true,
        editable: true,
        defaultEvent: serialPort.get('eventCode')
      });
    });
  const line = configuration.get('line');
  if (line.get('alarmProtocol') !== 'None' || line.get('lineSupervision')) {
    events.push({
      position: 20,
      active: true,
      editable: true
    });
  }
  const ipProtocolsDc09s = configuration.get('ipProtocolsDc09s');
  ipProtocolsDc09s.forEach((ipProtocolsDc09) => {
    events.push({
      position: ipProtocolsDc09.get('position') + 57,
      active: true,
      editable: true
    });
  });
  const bacnet = configuration.get('bacnet');
  if (bacnet.get('lanPosition')) {
    events.push({
      position: 63,
      active: true,
      editable: true
    });
  }
  return events;
}

function getEventCodes(
  events,
  protocol,
  predefinedAlarmCenter,
  defaultAlarmCenter
) {
  const eventCodes = predefinedAlarmCenter.get('securityRouterEventCodes');
  const defaultEventCodes = defaultAlarmCenter.get('securityRouterEventCodes');
  events.forEach((event, i) => {
    events = events.set(
      i,
      getCodesForEvent(event, protocol, eventCodes, defaultEventCodes)
    );
  });
  return events;
}

function getCodesForEvent(event, protocol, eventCodes, defaultEventCodes) {
  if (!eventCodes && !defaultEventCodes) return event;
  let codes;
  if (eventCodes) {
    codes = eventCodes.find(
      (code) => code.get('position') === event.get('position')
    );
  }
  if (!codes) {
    codes = defaultEventCodes.find(
      (code) => code.get('position') === event.get('position')
    );
  }
  if (protocol === 'sia' && codes) {
    const tamper = codes.get('tamper');
    const tamperRestore = codes.get('tamperRestore');
    if (tamper) event = event.set('tamper', tamper);
    if (tamperRestore) event = event.set('tamperRestore', tamperRestore);
    let alarm = codes.get('alarm');
    if (event.get('defaultEvent')) alarm = event.get('defaultEvent');
    return event
      .set('alarm', alarm)
      .set('alarmRestore', codes.get('restore'))
      .set('section', codes.get('sectionNumber'))
      .set('sectionText', codes.get('sectionText'))
      .set('editable', codes.get('editable'));
  } else if (codes) {
    const tamper = codes.getIn(['cid', 'tamperCode']);
    if (tamper) event = event.set('tamper', tamper);
    let alarm = codes.getIn(['cid', 'eventCode']);
    if (event.get('defaultEvent')) alarm = event.get('defaultEvent');
    return event
      .set('alarm', alarm)
      .set('section', codes.getIn(['cid', 'sectionNumber']))
      .set('editable', codes.get('editable'));
  }
  return event;
}

function addEvent(
  state,
  oldPosition,
  newPosition,
  predefinedAlarmCenters,
  defaultEvent
) {
  const alarmCenters = state.toJS();
  alarmCenters.forEach((alarmCenter, i) => {
    const events = alarmCenter.events;
    const eventIndex = events.findIndex(
      (event) => event.position === oldPosition
    );
    if (eventIndex !== -1) {
      state = state.setIn([i, 'events', eventIndex, 'position'], newPosition);
    } else {
      let eventCodes = [];
      if (alarmCenter.copiedFromPredefinedAlarmCenter === 'other') {
        eventCodes = predefinedAlarmCenters
          .find((predefined) => predefined.get('name') === 'iprec.predef.dc09')
          .get('securityRouterEventCodes');
      } else {
        eventCodes = predefinedAlarmCenters
          .find(
            (arc) =>
              arc.get('name') === alarmCenter.copiedFromPredefinedAlarmCenter
          )
          .get('securityRouterEventCodes');
      }
      const defaultEventCodes = predefinedAlarmCenters
        .find((predefined) => predefined.get('name') === 'iprec.predef.dc09')
        .get('securityRouterEventCodes');
      let event = {
        position: newPosition,
        active: true,
        editable: true
      };
      if (defaultEvent) {
        event.defaultEvent = defaultEvent;
      }
      event = getCodesForEvent(
        fromJS(event),
        alarmCenter.eventFormat,
        eventCodes,
        defaultEventCodes
      ).toJS();
      events.push(event);
      state = state.setIn([i, 'events'], fromJS(events));
    }
  });
  return state;
}

function removeEvent(state, position) {
  state.forEach((alarmCenter, i) => {
    const eventIndex = alarmCenter
      .get('events')
      .findIndex((event) => event.get('position') === position);
    if (eventIndex !== -1) {
      const events = alarmCenter.get('events').delete(eventIndex);
      state = state.setIn([i, 'events'], events);
    }
  });
  return state;
}

function lineEvent(
  state,
  name,
  value,
  alarmProtocol,
  lineSupervision,
  predefinedAlarmCenters
) {
  const defaultEventCodes = predefinedAlarmCenters
    .find((alarmCenter) => alarmCenter.get('name') === 'iprec.predef.dc09')
    .get('securityRouterEventCodes');
  state.forEach((alarmCenter, i) => {
    const events = alarmCenter.get('events');
    const existingEventIndex = events.findIndex(
      (event) => event.get('position') === 20
    );
    let eventCodes = [];
    if (alarmCenter.get('copiedFromPredefinedAlarmCenter') === 'other') {
      eventCodes = predefinedAlarmCenters
        .find((arc) => arc.get('name') === 'iprec.predef.dc09')
        .get('securityRouterEventCodes');
    } else {
      eventCodes = predefinedAlarmCenters
        .find(
          (arc) =>
            arc.get('name') ===
            alarmCenter.get('copiedFromPredefinedAlarmCenter')
        )
        .get('securityRouterEventCodes');
    }
    if (existingEventIndex !== -1) {
      if (name === 'lineSupervision') {
        if (value) {
          let event = events.get(existingEventIndex);
          event = getCodesForEvent(
            event,
            alarmCenter.get('eventFormat'),
            eventCodes,
            defaultEventCodes
          );
          state = state.setIn([i, 'events', existingEventIndex], event);
        } else if (alarmProtocol !== 'None') {
          return state;
        } else {
          state = state.setIn([i, 'events'], events.delete(existingEventIndex));
        }
      } else if (value === 'None' && !lineSupervision) {
        state = state.setIn([i, 'events'], events.delete(existingEventIndex));
      }
    } else {
      let event = {
        position: 20,
        active: true,
        editable: true
      };
      const events1 = alarmCenter.get('events').toJS();
      event = getCodesForEvent(
        fromJS(event),
        alarmCenter.get('eventFormat'),
        eventCodes,
        defaultEventCodes
      ).toJS();
      events1.push(event);
      state = state.setIn([i, 'events'], fromJS(events1));
    }
  });
  return state;
}
const singlePaths = ['ethernet', 'mobile'];
function updatePathSettings(state, newPath) {
  const oldPath = state.get('communicationPath');
  if (
    oldPath !== 'select' &&
    !(singlePaths.includes(oldPath) && singlePaths.includes(newPath))
  ) {
    state = state.set('alarmClass', 'select');
    state = state.set('pollingInterval', 'select');
  }
  if (
    state.get('samePathSettings') ||
    (oldPath !== 'all' && ['ethernet', 'mobile'].includes(newPath))
  ) {
    return state;
  }

  if (oldPath === 'all' && newPath === 'select') {
    return resetPathSettings(state);
  }

  if (newPath === 'all') {
    return resetPathSettings(state)
      .set(`primary${oldPath}ReceiverIp`, state.get('primaryReceiverIp'))
      .set(`primary${oldPath}ReceiverPort`, state.get('primaryReceiverPort'))
      .set(`secondary${oldPath}ReceiverIp`, state.get('secondaryReceiverIp'))
      .set(
        `secondary${oldPath}ReceiverPort`,
        state.get('secondaryReceiverPort')
      );
  }

  return resetPathSettings(state)
    .set('primaryReceiverIp', state.get(`primary${newPath}ReceiverIp`))
    .set('primaryReceiverPort', state.get(`primary${newPath}ReceiverPort`))
    .set('secondaryReceiverIp', state.get(`secondary${newPath}ReceiverIp`))
    .set('secondaryReceiverPort', state.get(`secondary${newPath}ReceiverPort`));
}

function toggleSamePathSettings(state, activate) {
  return activate
    ? resetPathSettings(state)
        .set('primaryReceiverIp', state.get('primaryethernetReceiverIp'))
        .set('primaryReceiverPort', state.get('primaryethernetReceiverPort'))
        .set('secondaryReceiverIp', state.get('secondaryethernetReceiverIp'))
        .set(
          'secondaryReceiverPort',
          state.get('secondaryethernetReceiverPort')
        )
    : resetPathSettings(state)
        .set('primaryethernetReceiverIp', state.get('primaryReceiverIp'))
        .set('primaryethernetReceiverPort', state.get('primaryReceiverPort'))
        .set('primarymobileReceiverIp', state.get('primaryReceiverIp'))
        .set('primarymobileReceiverPort', state.get('primaryReceiverPort'))
        .set('secondaryethernetReceiverIp', state.get('secondaryReceiverIp'))
        .set(
          'secondaryethernetReceiverPort',
          state.get('secondaryReceiverPort')
        )
        .set('secondarymobileReceiverIp', state.get('secondaryReceiverIp'))
        .set('secondarymobileReceiverPort', state.get('secondaryReceiverPort'));
}

function resetPathSettings(state) {
  return state.merge(fromJS(INITIAL_PATH_SETTINGS));
}

function changeAlarmCenter(state, action) {
  const value = action.payload.value;
  const index = action.payload.index;
  const position = state.getIn([index, 'position']);
  const { predefinedAlarmCenters } = action.payload.params;
  const predefinedAlarmCenter = predefinedAlarmCenters.find(
    (arc) => arc.get('name') === value
  );

  const id = state.getIn([index, 'id']);
  if (value === 'other') {
    return state
      .set(index, fromJS(INITIAL_ALARM_CENTER_JSON))
      .setIn([index, 'encryptedOptional'], true)
      .setIn([index, 'copiedFromPredefinedAlarmCenter'], value)
      .setIn([index, 'position'], position)
      .setIn([index, 'id'], id);
  }

  const primaryReceiver = predefinedAlarmCenter.getIn(
    ['encrypted', 'primaryReceiver'],
    predefinedAlarmCenter.getIn(['unEncrypted', 'primaryReceiver'], '')
  );

  const secondaryReceiver = predefinedAlarmCenter.getIn(
    ['encrypted', 'secondaryReceiver'],
    predefinedAlarmCenter.getIn(['unEncrypted', 'secondaryReceiver'], '')
  );

  const primaryIps = primaryReceiver
    ? formatReceiver(primaryReceiver, true)
    : { ip: '', port: '' };

  const secondaryIps = secondaryReceiver
    ? formatReceiver(secondaryReceiver)
    : { ip: '', port: '' };

  const samePathSettings =
    !primaryIps.primaryethernetReceiverIp ||
    !primaryIps.primarymobileReceiverIp;
  const hadDc09Before = state.getIn([index, 'hasDc09']);
  if (!hadDc09Before && predefinedAlarmCenter.get('dc09')) {
    state = state.setIn([index, 'v4'], Map());
  } else if (hadDc09Before && !predefinedAlarmCenter.get('dc09')) {
    state = state.setIn([index, 'dc09'], Map());
  }
  if (predefinedAlarmCenter.get('dc09')) {
    state = state
      .setIn(
        [index, 'dc09', 'eagleEyeCms'],
        predefinedAlarmCenter.get('eagleEyeCms')
      )
      .setIn([index, 'dc09', 'prefix'], predefinedAlarmCenter.get('prefix'));
  }
  return state
    .setIn([index, 'name'], predefinedAlarmCenter.get('name'))
    .setIn(
      [index, 'copiedFromPredefinedAlarmCenter'],
      predefinedAlarmCenter.get('name')
    )
    .setIn([index, 'primaryReceiverIp'], fromJS(primaryIps.primaryReceiverIp))
    .setIn(
      [index, 'secondaryReceiverIp'],
      fromJS(secondaryIps.secondaryReceiverIp)
    )
    .setIn(
      [index, 'primaryReceiverPort'],
      fromJS(primaryIps.primaryReceiverPort)
    )
    .setIn(
      [index, 'secondaryReceiverPort'],
      fromJS(secondaryIps.secondaryReceiverPort)
    )
    .setIn(
      [index, 'primaryethernetReceiverIp'],
      fromJS(primaryIps.primaryethernetReceiverIp)
    )
    .setIn(
      [index, 'secondaryethernetReceiverIp'],
      fromJS(secondaryIps.secondaryethernetReceiverIp)
    )
    .setIn(
      [index, 'primaryethernetReceiverPort'],
      fromJS(primaryIps.primaryethernetReceiverPort)
    )
    .setIn(
      [index, 'secondaryethernetReceiverPort'],
      fromJS(secondaryIps.secondaryethernetReceiverPort)
    )
    .setIn(
      [index, 'primarymobileReceiverIp'],
      fromJS(primaryIps.primarymobileReceiverIp)
    )
    .setIn(
      [index, 'secondarymobileReceiverIp'],
      fromJS(secondaryIps.secondarymobileReceiverIp)
    )
    .setIn(
      [index, 'primarymobileReceiverPort'],
      fromJS(primaryIps.primarymobileReceiverPort)
    )
    .setIn(
      [index, 'secondarymobileReceiverPort'],
      fromJS(secondaryIps.secondarymobileReceiverPort)
    )
    .setIn([index, 'samePathSettings'], samePathSettings)
    .setIn([index, 'encrypted'], predefinedAlarmCenter.get('encrypted'))
    .setIn(
      [index, 'encryptedOptional'],
      predefinedAlarmCenter.get('encrypted') &&
        predefinedAlarmCenter.get('unEncrypted')
    )
    .setIn([index, 'hasDc09'], predefinedAlarmCenter.get('dc09'))
    .setIn([index, 'position'], position)
    .setIn([index, 'communicationPath'], 'select')
    .setIn([index, 'pollingInterval'], 'select')
    .setIn(
      [index, 'pollingScheme'],
      predefinedAlarmCenter.get('pollingScheme') || 'standard'
    )
    .setIn(
      [index, 'lockedPollingScheme'],
      !!predefinedAlarmCenter.get('pollingScheme')
    );
}
