/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  inputNotificationTexts: [],
  errorInputNotificationTexts: [],
  lineNotificationTexts: {},
  serialPortNotificationTexts: [],
  ipProtocolsDc09NotificationTexts: []
});

export default function eagleEyeTextsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { type, name, value, params } = action.payload;
      if (type === 'eagleEyeTexts') {
        const { type: inputType, position, label, lanPosition } = params;
        let existingIndex;
        switch (inputType) {
          case 'input':
            let inputNotificationTexts = state.get('inputNotificationTexts');
            existingIndex = inputNotificationTexts.findIndex(
              (texts) => texts.get('inputPosition') === position
            );
            if (existingIndex === -1) {
              inputNotificationTexts = inputNotificationTexts.push(
                fromJS({
                  inputPosition: position,
                  alarmText: name === 'alarmText' ? value : '',
                  restoreText: name === 'restoreText' ? value : '',
                  tamperText: name === 'tamperText' ? value : '',
                  tamperRestoreText: name === 'tamperRestoreText' ? value : ''
                })
              );
              return state.set(
                'inputNotificationTexts',
                inputNotificationTexts
              );
            }
            return state.setIn(
              ['inputNotificationTexts', existingIndex, name],
              value
            );
          case 'errorInput':
            let errorInputNotificationTexts = state.get(
              'errorInputNotificationTexts'
            );
            existingIndex = errorInputNotificationTexts.findIndex(
              (texts) => texts.get('errorInputLabel') === label
            );
            if (existingIndex === -1) {
              errorInputNotificationTexts = errorInputNotificationTexts.push(
                fromJS({
                  errorInputLabel: label,
                  alarmText: name === 'alarmText' ? value : '',
                  restoreText: name === 'restoreText' ? value : ''
                })
              );
              return state.set(
                'errorInputNotificationTexts',
                errorInputNotificationTexts
              );
            }
            return state.setIn(
              ['errorInputNotificationTexts', existingIndex, name],
              value
            );
          case 'line':
            return state.setIn(['lineNotificationTexts', name], value);
          case 'serialPort':
            let serialPortNotificationTexts = state.get(
              'serialPortNotificationTexts'
            );
            existingIndex = serialPortNotificationTexts.findIndex(
              (texts) => texts.get('position') === position
            );
            if (existingIndex === -1) {
              serialPortNotificationTexts = serialPortNotificationTexts.push(
                fromJS({
                  position: position,
                  alarmText: name === 'alarmText' ? value : '',
                  restoreText: name === 'restoreText' ? value : '',
                  tamperText: name === 'tamperText' ? value : '',
                  tamperRestoreText: name === 'tamperRestoreText' ? value : ''
                })
              );
              return state.set(
                'serialPortNotificationTexts',
                serialPortNotificationTexts
              );
            }
            return state.setIn(
              ['serialPortNotificationTexts', existingIndex, name],
              value
            );
          case 'ipProtocolsDc09':
            let ipProtocolsDc09NotificationTexts = state.get(
              'ipProtocolsDc09NotificationTexts'
            );
            existingIndex = ipProtocolsDc09NotificationTexts.findIndex(
              (texts) =>
                texts.get('position') === position &&
                texts.get('lanPosition') === lanPosition
            );
            if (existingIndex === -1) {
              ipProtocolsDc09NotificationTexts =
                ipProtocolsDc09NotificationTexts.push(
                  fromJS({
                    position,
                    alarmText: name === 'alarmText' ? value : '',
                    restoreText: name === 'restoreText' ? value : '',
                    tamperText: name === 'tamperText' ? value : '',
                    tamperRestoreText:
                      name === 'tamperRestoreText' ? value : '',
                    lanPosition
                  })
                );
              return state.set(
                'ipProtocolsDc09NotificationTexts',
                ipProtocolsDc09NotificationTexts
              );
            }
            return state.setIn(
              ['ipProtocolsDc09NotificationTexts', existingIndex, name],
              value
            );
          default:
            break;
        }
        return state;
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
