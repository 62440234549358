/** @format */

import loadEagleEyeReceivers from './eagleEyeReceivers';
import loadAdditionalServices from './additionalServices';
import loadConfiguration from './configuration';
import loadDalmStatus from './dalmStatus';
import loadDalmStatuses from './dalmStatuses';
import loadEwmLog from './ewmLog';
import loadPollingIntervals from './pollingIntervals';
import loadDataPlans from './dataPlans';
import loadSecurityRouterConfiguration from './securityRouterConfiguration';
import loadSecurityRouterServices from './securityRouterServices';

export default {
  loadEagleEyeReceivers,
  loadAdditionalServices,
  loadConfiguration,
  loadDalmStatus,
  loadDalmStatuses,
  loadEwmLog,
  loadPollingIntervals,
  loadDataPlans,
  loadSecurityRouterConfiguration,
  loadSecurityRouterServices
};
