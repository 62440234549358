/** @format */

import get from './get';
import getShares from './getShares';

export default {
  get,
  getShares,
  sortBy,
  openMoveDialog,
  onSearchBarInputChange,
  setStateFromQuery
};

function setStateFromQuery(params) {
  return {
    type: 'CONFIGURATION_LIST_INIT_STATE',
    payload: params
  };
}

function sortBy(column) {
  return (dispatch, getState) => {
    const state = getState();
    const currentColumn = state.configuration.getIn(['list', 'sortedBy']);
    const currentOrder = state.configuration.getIn(['list', 'sortOrder']);
    let newOrder = 'descending';

    if (currentColumn === column) {
      newOrder = currentOrder === 'descending' ? 'ascending' : 'descending';
    }

    dispatch({
      type: 'CONFIGURATION_LIST_SORT',
      payload: {
        order: newOrder,
        column
      }
    });
  };
}

function openMoveDialog(configurationId) {
  return (dispatch, getState) => {
    const state = getState();
    const configuration = state.configuration
      .getIn(['list', 'configurations'])
      .find((c) => c.get('id') === configurationId);

    dispatch({
      type: 'SHOW_MOVE_CONFIGURATION_MODAL',
      payload: {
        configuration
      }
    });
  };
}

function onSearchBarInputChange(value) {
  return {
    type: 'CONFIGURATIONS_SEARCH_BAR_INPUT_CHANGE',
    payload: value
  };
}
