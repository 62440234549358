/** @format */

import request from '@edgeguideab/client-request';
import config from 'config.json';
import urls from 'utils/urls';
import history from 'utils/history';
import loginRedirect from './loginRedirect';
import { getLogin } from 'utils/login';
import { showRememberMeBox } from 'config';

export default function checkSession({ successRedirect } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: 'SESSION_CHECK_STARTED' });
    let encryptedUsername = null;
    let encryptedPassword = null;
    if (showRememberMeBox) {
      encryptedUsername = window.localStorage.getItem('encryptedUsername');
      encryptedPassword = window.localStorage.getItem('encryptedPassword');
      if (!encryptedUsername || !encryptedPassword) {
        const loginCredentials = await getLogin();
        if (loginCredentials) {
          encryptedUsername = loginCredentials.username;
          encryptedPassword = loginCredentials.password;
        }
      }
    }
    let response;
    try {
      response = await request.post(`${config.apiServer}/sessions/check`, {
        body: {
          encryptedUsername,
          encryptedPassword
        }
      });
    } catch (errorResponse) {
      dispatch({
        type: 'SESSION_CHECK_FAILED',
        payload: { msg: errorResponse.body.msg || 'errors.maintenance' }
      });
      if (
        history.location.pathname === urls.loginOTC && // TODO: Not sure if this is correct
        errorResponse.body.hasValidCredentials
      ) {
        history.push(urls.loginOTC);
      }
      return;
    }

    window.localStorage.setItem('csrfToken', response.body.csrfToken);
    dispatch({ type: 'SESSION_CHECK_DONE', payload: response.body });

    const hasStartPage = state.global.get('ewmFeatures').includes('startPage');
    if (successRedirect) {
      loginRedirect({
        hasStartPageAccess: response.body.user.acl.startPage.read.length > 0,
        hasStartPage
      });
    }
  };
}
