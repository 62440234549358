/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  data: '0MB',
  apn: '',
  simPin: '',
  triggerEmails: [
    {
      email: ''
    }
  ],
  dataPlans: []
});

export default function mobileDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SECURITY_ROUTER_CONFIGURATION_EDIT_INPUT_CHANGE': {
      if (action.payload.type === 'mobileData') {
        const name = action.payload.name;
        if (name === 'email') {
          const index = action.payload.index;
          return state.setIn(
            ['triggerEmails', index, name],
            action.payload.value
          );
        } else if (name === 'data') {
          state = state.set('apn', '');
          state = state.set('simPin', '');
        }
        return state.set(name, action.payload.value);
      }
      return state;
    }
    case 'MOBILE_DATA_ADD_TRIGGER': {
      const triggers = state.get('triggerEmails').push(
        fromJS({
          email: ''
        })
      );
      return state.set('triggerEmails', triggers);
    }
    case 'MOBILE_DATA_REMOVE_TRIGGER': {
      let triggers = state.get('triggerEmails').delete(action.payload.index);
      if (triggers.size === 0) {
        triggers = triggers.push(
          fromJS({
            email: ''
          })
        );
      }
      return state.set('triggerEmails', triggers);
    }
    case 'LOAD_DATA_PLANS_DONE': {
      return state.set('dataPlans', action.payload);
    }
    default: {
      return state;
    }
  }
}
