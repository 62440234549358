/** @format */

import request from '@edgeguideab/client-request';
import history from 'utils/history';
import urls from 'utils/urls';
import config from 'config';
import { forgetLogin } from 'utils/login';

function logout() {
  return (dispatch) => {
    window.localStorage.removeItem('encryptedUsername');
    window.localStorage.removeItem('encryptedPassword');
    forgetLogin();
    dispatch({
      type: 'SESSION_LOGOUT_STARTED'
    });
    request
      .post(`${config.apiServer}/sessions/logout`)
      .then((response) => {
        dispatch({
          type: 'SESSION_LOGOUT_DONE',
          payload: { msg: response.body.msg }
        });
        history.push('/');
      })
      .catch((response) => {
        dispatch({
          type: 'SESSION_LOGOUT_FAILED',
          payload: { msg: response.body.msg }
        });
        history.push(urls.login);
      });
  };
}

function resetOtc() {
  return (dispatch) => {
    dispatch({
      type: 'SESSION_LOGOUT_STARTED'
    });
    request
      .post(`${config.apiServer}/sessions/logout`)
      .then((response) => {
        dispatch({
          type: 'SESSION_LOGOUT_DONE',
          payload: { msg: response.body.msg }
        });
        history.push(urls.login);
      })
      .catch((response) => {
        dispatch({
          type: 'SESSION_LOGOUT_FAILED',
          payload: { msg: response.body.msg }
        });
        history.push(urls.login);
      });
  };
}

export { logout, resetOtc };
