/** @format */

export default function validateContactInformation(
  invoice,
  country,
  editConfigurationFeatures
) {
  return {
    type: 'object',
    keys: {
      invoice: {
        type: 'object',
        keys: {
          to: {
            type: 'string',
            errorCode: 'validation.invoice.to',
            allowNull: !editConfigurationFeatures.includes('invoice')
          },
          organizationName: {
            type: 'string',
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.invoice.organizationName'
          },
          vat: {
            type: 'string',
            allowNull:
              invoice.to !== 'customer' ||
              !country.get('endCustomerInvoiceVat'),
            errorCode: 'validation.invoice.vat'
          },
          address: {
            type: 'string',
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.address.notselected'
          },
          zipCode: {
            type: 'string',
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.required.invoiceZipCode'
          },
          city: {
            type: 'string',
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.required.invoiceCity'
          },
          additionalEmail: {
            type: 'string',
            condition: (email) =>
              /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                email
              ),
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.email.valid'
          },
          country: {
            type: 'string',
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.invoice.country'
          },
          name: {
            type: 'string',
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.required.name'
          },
          email: {
            type: 'string',
            condition: (email) =>
              /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                email
              ),
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.email.valid'
          },
          phoneNumber: {
            type: 'string',
            condition: (phoneNumber) => /^\+.*$/.test(phoneNumber),
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.phone.notphonenumber'
          },
          mobilePhoneNumber: {
            type: 'string',
            condition: (phoneNumber) => /^\+.*$/.test(phoneNumber),
            allowNull: invoice.to !== 'customer',
            errorCode: 'validation.phone.notphonenumber'
          }
        }
      }
    }
  };
}
