/** @format */

import { combineReducers } from 'redux';
import sessionReducer from './session';
import globalReducer from './global';
import passwordRecoveryReducer from './passwordRecovery';
import userReducer from './user';
import userListReducer from './userList';
import organizationReducer from './organization';
import configurationReducer from './configuration';
import userActivationReducer from './userActivation';
import firmwareReducer from './firmware';
import kernelReducer from './kernel';
import mobileModemFirmwareReducer from './mobileModemFirmware';
import modalsReducer from './modals';
import simCardReducer from './simCard';
import registerReducer from './register';
import eagleEyeReducer from './eagleEye';
import thingsReducer from './things';
import documentsReducer from './documents';
import serviceLogsReducer from './serviceLogs';

const allReducers = combineReducers({
  user: userReducer,
  userList: userListReducer,
  session: sessionReducer,
  global: globalReducer,
  organization: organizationReducer,
  configuration: configurationReducer,
  passwordRecovery: passwordRecoveryReducer,
  userActivation: userActivationReducer,
  modals: modalsReducer,
  firmware: firmwareReducer,
  kernel: kernelReducer,
  simCard: simCardReducer,
  register: registerReducer,
  mobileModemFirmware: mobileModemFirmwareReducer,
  eagleEye: eagleEyeReducer,
  things: thingsReducer,
  documents: documentsReducer,
  serviceLogs: serviceLogsReducer
});

export default allReducers;
