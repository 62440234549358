/** @format */

import urls from 'utils/urls';
import history from 'utils/history';

export default function loginRedirect({ hasStartPageAccess, hasStartPage }) {
  let url = urls.configurationsList;
  if (hasStartPage) {
    url = hasStartPageAccess ? urls.startPage : urls.eagleEyeList;
  }
  history.push(url);
}
