/** @format */

export default function validateReceiver({ receiver, configuration }) {
  const isSet = receiver.label || receiver.type || receiver.address;
  const lastArcIsFull =
    Array.isArray(configuration.alarmCenters) &&
    configuration.alarmCenters.some(
      (alarmCenter) =>
        alarmCenter &&
        alarmCenter.position === 3 &&
        alarmCenter.secondaryReceveiverIp
    );

  return {
    type: 'object',
    condition: (x) => x.position !== 7 || !lastArcIsFull || !isSet,
    conditionErrorCode: 'configuration.wrong.rec7.not.avail',
    keys: isSet
      ? {
          label: {
            type: 'string',
            errorCode: 'validation.required.label'
          },
          type: {
            type: 'string',
            errorCode: 'validation.type.notselected'
          },
          address:
            receiver.type === 'email'
              ? {
                  type: 'string',
                  condition: (email) =>
                    /^\w+([.-]?\w+)+@\w+([-.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/.test(
                      email
                    ),
                  errorCode: 'validation.address.notselected'
                }
              : {
                  type: 'string',
                  condition: (address) => /^\+.+$/.test(address),
                  errorCode: 'validation.receiver.phoneNumber.not.valid'
                }
        }
      : {}
  };
}
