/** @format */

import { fromJS } from 'immutable';
import current from './current';
import currentSecurityRouter from './currentSecurityRouter';
import folders from './folders';
import alarmCenters from './alarmCenters';
import list from './list';
import dalmStatus from './dalmStatus';
import dalmStatusList from './dalmStatusList';
import operations from './operations';
import pollingIntervals from './pollingIntervals';
import eventLog from './eventLog';
import additionalServices from './additionalServices';
import securityRouterServicesModals from './securityRouterServicesModals';
import mutation from './mutation';

const INITIAL_STATE = fromJS({
  current: current(undefined, { type: '' }),
  currentSecurityRouter: currentSecurityRouter(undefined, { type: '' }),
  folders: folders(undefined, { type: '' }),
  alarmCenters: alarmCenters(undefined, { type: '' }),
  list: list(undefined, { type: '' }),
  dalmStatus: dalmStatus(undefined, { type: '' }),
  dalmStatusList: dalmStatusList(undefined, { type: '' }),
  operations: operations(undefined, { type: '' }),
  pollingIntervals: pollingIntervals(undefined, { type: '' }),
  eventLog: eventLog(undefined, { type: '' }),
  additionalServices: additionalServices(undefined, { type: '' }),
  securityRouterServicesModals: securityRouterServicesModals(undefined, {
    type: ''
  }),
  mutation: mutation(undefined, { type: '' })
});

export default function configurationReducer(state = INITIAL_STATE, action) {
  state = state.set('current', current(state.get('current'), action));
  state = state.set(
    'currentSecurityRouter',
    currentSecurityRouter(state.get('currentSecurityRouter'), action)
  );
  state = state.set('folders', folders(state.get('folders'), action));
  state = state.set(
    'alarmCenters',
    alarmCenters(state.get('alarmCenters'), action)
  );
  state = state.set('list', list(state.get('list'), action));
  state = state.set('dalmStatus', dalmStatus(state.get('dalmStatus'), action));
  state = state.set(
    'dalmStatusList',
    dalmStatusList(state.get('dalmStatusList'), action)
  );
  state = state.set('operations', operations(state.get('operations'), action));
  state = state.set(
    'pollingIntervals',
    pollingIntervals(state.get('pollingIntervals'), action)
  );
  state = state.set('eventLog', eventLog(state.get('eventLog'), action));
  state = state.set(
    'additionalServices',
    additionalServices(state.get('additionalServices'), action)
  );
  state = state.set(
    'securityRouterServicesModals',
    securityRouterServicesModals(
      state.get('securityRouterServicesModals'),
      action
    )
  );
  state = state.set('mutation', mutation(state.get('mutation'), action));
  switch (action.type) {
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
