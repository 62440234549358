/** @format */

import { fromJS } from 'immutable';

const MAX_FAILED_LOGIN_ATTEMPTS_BEFORE_CAPTCHA = 5;

const INITIAL_LOGIN_FORM_JSON = {
  username: '',
  password: '',
  remember: false
};

const INITIAL_STATE = fromJS({
  isFetching: false,
  visibleForm: false,
  loginForm: INITIAL_LOGIN_FORM_JSON,
  loginMode: 'credentials',
  failedLoginAttempts: 0,
  user: {
    id: '',
    hasShareExternalService: false,
    organizationIsPro: false,
    securityActivationDuration: 0
  },
  message: '',
  error: '',
  loggedIn: false,
  isChecking: false,
  checkedSession: false,
  samlLink: undefined,
  validation: {
    username: '',
    password: ''
  },
  resetCaptcha: false
});

const sessions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_SAML_LINK_DONE': {
      if (state.get('checkedSession')) {
        state = state.set('visibleForm', true);
      }
      return state.set('samlLink', action.payload.url);
    }
    case 'SESSION_SEND_OTC_START': {
      return state.set('isFetching', true).set('error', '').set('message', '');
    }
    case 'SESSION_SEND_OTC_DONE': {
      return state
        .set('error', '')
        .set('user', fromJS(action.payload.user))
        .set('loggedIn', true)
        .set('isFetching', false)
        .set('loginForm', fromJS(INITIAL_LOGIN_FORM_JSON));
    }
    case 'SESSION_SEND_OTC_FAILED': {
      return state
        .set('error', action.payload.msg)
        .set('message', '')
        .set('isFetching', false)
        .set(
          'failedLoginAttempts',
          action.payload.msg === 'validation.captcha.required'
            ? MAX_FAILED_LOGIN_ATTEMPTS_BEFORE_CAPTCHA
            : state.get('failedLoginAttempts') + 1
        );
    }
    case 'SESSION_LOGIN_FORM_CHANGE': {
      return state
        .setIn(['validation', action.payload.name], '')
        .set('error', '')
        .setIn(['loginForm', action.payload.name], action.payload.value);
    }
    case 'SESSION_LOGIN_STARTED': {
      return state.set('isFetching', true).set('error', '').set('message', '');
    }
    case 'SESSION_TWO_FACTOR_VIEW': {
      return state.set('loginMode', '2FA').set('failedLoginAttempts', 0);
    }
    case 'SESSION_LOGIN_DONE': {
      const loggedIn = action.payload.loggedIn;
      return state
        .set('error', '')
        .set('loggedIn', loggedIn)
        .set('user', fromJS(action.payload.user))
        .set('isFetching', false)
        .set('failedLoginAttempts', 0)
        .set('loginForm', fromJS(INITIAL_LOGIN_FORM_JSON));
    }
    case 'SESSION_CHECK_STARTED': {
      return state.set('checkedSession', false).set('isChecking', true);
    }
    case 'SESSION_CHECK_DONE': {
      return state
        .set('loggedIn', true)
        .set('checkedSession', true)
        .set('isChecking', false)
        .set('user', fromJS(action.payload.user))
        .set('sequenceChannels', fromJS(action.payload.sequenceChannels));
    }
    case 'SESSION_CHECK_FAILED': {
      state = state.set('checkedSession', true).set('isChecking', false);
      if (state.get('samlLink') !== undefined) {
        return state.set('visibleForm', true);
      }
      return state;
    }
    case 'SESSION_LOGIN_MISSING_USERNAME': {
      return state.setIn(['validation', 'username'], action.payload.msg);
    }
    case 'SESSION_LOGIN_MISSING_PASSWORD': {
      return state.setIn(['validation', 'password'], action.payload.msg);
    }
    case 'SESSION_LOGIN_FAILED': {
      const newState = state
        .set('error', action.payload.msg)
        .set('message', '')
        .set('isFetching', false)
        .set('resetCaptcha', !!action.payload.resetCaptcha);
      if (action.payload.msg === 'validation.captcha.required') {
        return newState.set(
          'failedLoginAttempts',
          MAX_FAILED_LOGIN_ATTEMPTS_BEFORE_CAPTCHA
        );
      }
      return newState.set(
        'failedLoginAttempts',
        state.get('failedLoginAttempts') + 1
      );
    }
    case 'ORGANIZATION_REQUEST_DOWNGRADE_DONE': {
      return state.setIn(['user', 'organizationIsPro'], false);
    }
    case 'ORGANIZATION_UPDATE_DONE': {
      return state.setIn(
        ['user', 'hasShareExternalService'],
        action.payload.hasShareExternalService
      );
    }
    case 'ORGANIZATION_LOGO_UPLOADED': {
      if (state.getIn(['user', 'role']) === 'superUser') {
        return state;
      }
      return state.setIn(['user', 'organizationLogo'], true);
    }
    case 'ORGANIZATION_REMOVE_LOGO_START': {
      return state.setIn(['user', 'organizationLogo'], false);
    }
    case 'ORGANIZATION_REMOVE_LOGO_FAILED': {
      return state.setIn(['user', 'organizationLogo'], true);
    }
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE.set('visibleForm', state.get('visibleForm')).set(
        'checkedSession',
        state.get('checkedSession')
      );
    }
    default: {
      return state;
    }
  }
};

export default sessions;
