/** @format */

import expect from '@edgeguideab/expect';
import request from '@edgeguideab/client-request';
import config from 'config.json';
import urls from 'utils/urls';
import history from 'utils/history';
import configurationListActions from 'actions/configuration/list';
import configurationEditActions from 'actions/configuration/edit/index';

export default {
  deleteConfiguration,
  loadEwmConfig,
  loadEwmFeatures,
  moveFolder,
  exportConfiguration,
  validateFriendlyId,
  activateUser,
  getApplicationVersion
};

function getApplicationVersion() {
  return async (dispatch) => {
    try {
      const response = await request.get(
        `${config.apiServer}/application-version`
      );
      dispatch({
        type: 'LOAD_APP_VERSION',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_APP_VERSION_FAILED',
        payload: response.body
      });
    }
  };
}

function loadEwmFeatures() {
  return async (dispatch) => {
    try {
      const response = await request.get(`${config.apiServer}/ewm-features`);
      dispatch({
        type: 'LOAD_EWM_FEATURES',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_EWM_FEATURES_FAILED',
        payload: response.body
      });
    }
  };
}
function loadEwmConfig() {
  return async (dispatch) => {
    try {
      const response = await request.get(`${config.apiServer}/server-config`);
      dispatch({
        type: 'LOAD_EWM_CONFIG',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_EWM_CONFIG_FAILED',
        payload: response.body
      });
    }
  };
}

function moveFolder() {
  return (dispatch, getState) => {
    const state = getState();
    const folderId = state.modals.getIn([
      'moveConfiguration',
      'form',
      'folderId'
    ]);
    const configurationId = state.modals.getIn([
      'moveConfiguration',
      'configuration',
      'id'
    ]);

    dispatch(configurationEditActions.move({ configurationId, folderId }));
  };
}

function deleteConfiguration({ urlAfterDeletion }) {
  return async (dispatch, getState) => {
    const state = getState();
    const id = state.modals.getIn(['deleteConfiguration', 'id']);
    dispatch({ type: 'DELETE_CONFIGURATION_START' });

    try {
      await request.delete(`${config.apiServer}/configurations/${id}`);
      dispatch({ type: 'DELETE_CONFIGURATION_DONE' });
      dispatch(configurationListActions.get({ fromFolder: true }));
      setTimeout(() => {
        dispatch({ type: 'HIDE_CONFIGURATION_DELETION_MODAL' });
        if (urlAfterDeletion) history.replace(urlAfterDeletion);
      }, 1000);
    } catch (response) {
      dispatch({
        type: 'DELETE_CONFIGURATION_FAILED',
        payload: { msg: response.body && response.body.msg }
      });
    }
  };
}

function validateFriendlyId(securityRouter) {
  return async (dispatch, getState) => {
    const state = getState();
    const id = state.configuration.getIn([
      securityRouter ? 'currentSecurityRouter' : 'current',
      'configuration',
      'id'
    ]);
    const friendlyId = state.modals.getIn(['exportConfiguration', 'form']);

    const expectations = expect(
      {
        friendlyId: {
          type: 'number',
          parse: true,
          errorCode: 'validation.company.id.type',
          allowNullErrorCode: 'validation.company.id.required'
        }
      },
      { friendlyId }
    );

    if (!expectations.wereMet()) {
      return dispatch({
        type: 'EXPORT_CONFIGURATION_FORM_ERROR',
        payload: expectations.errors().friendlyId
      });
    }

    try {
      await request.get(
        `${config.apiServer}/configurations/${id}/export/check/${friendlyId}`
      );
      dispatch({
        type: 'EXPORT_CONFIGURATION_NEXT_DIALOG'
      });
    } catch (response) {
      dispatch({
        type: 'EXPORT_CONFIGURATION_FORM_ERROR',
        payload: response.body.msg
      });
    }
  };
}

function exportConfiguration(
  securityRouter,
  { configurationId, organizationId, folderId } = {}
) {
  return async (dispatch, getState) => {
    const state = getState();
    const id =
      configurationId ||
      state.configuration.getIn([
        securityRouter ? 'currentSecurityRouter' : 'current',
        'configuration',
        'id'
      ]);
    const friendlyId = state.modals.getIn(['exportConfiguration', 'form']);
    dispatch({
      type: 'EXPORT_CONFIGURATION_START'
    });
    try {
      await request.put(`${config.apiServer}/configurations/${id}/export`, {
        body: { friendlyId, organizationId, folderId }
      });
      dispatch({ type: 'EXPORT_CONFIGURATION_DONE' });
      setTimeout(
        () => dispatch({ type: 'HIDE_EXPORT_CONFIGURATION_MODAL' }),
        1000
      );
      history.replace(urls.configurationsList);
    } catch (response) {
      dispatch({ type: 'EXPORT_CONFIGURATION_FAILED' });
      dispatch({
        type: 'EXPORT_CONFIGURATION_FORM_ERROR',
        payload: response.body.msg
      });
    }
  };
}

function activateUser(userId) {
  return async (dispatch) => {
    dispatch({ type: 'ADMIN_ACTIVATE_USER_START' });

    try {
      await request.post(
        `${config.apiServer}/users/:userId/activate`.replace(':userId', userId)
      );
    } catch (response) {
      dispatch({ type: 'ADMIN_ACTIVATE_USER_FAILED' });
      return dispatch({
        type: 'ADMIN_ACTIVATE_USER_FORM_ERROR',
        payload: {
          msg: response.body.msg || '',
          param: response.body.param || ''
        }
      });
    }
    dispatch({ type: 'ADMIN_ACTIVATE_USER_DONE', payload: userId });
    dispatch({ type: 'ACTIVATE_USER_MODAL_HIDE' });
  };
}
