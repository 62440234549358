/** @format */

import request from '@edgeguideab/client-request';
import loginRedirect from './loginRedirect';
import config from 'config';

export default function sendOtc() {
  return async (dispatch, getState) => {
    const state = getState();
    const oneTimeCode = state.session.getIn(['loginForm', 'oneTimeCode']);

    dispatch({ type: 'SESSION_SEND_OTC_START' });
    let response;
    try {
      response = await request.post(`${config.apiServer}/sessions/login/otc`, {
        body: { oneTimeCode }
      });
    } catch (errorResponse) {
      return dispatch({
        type: 'SESSION_SEND_OTC_FAILED',
        payload: { msg: errorResponse.body.msg || 'errors.maintenance' }
      });
    }
    dispatch({
      type: 'SESSION_SEND_OTC_DONE',
      payload: { user: response.body.user }
    });
    window.localStorage.setItem('csrfToken', response.body.csrfToken);
    const hasStartPage = state.global.get('ewmFeatures').includes('startPage');
    window.location.reload(true);
    loginRedirect({
      hasStartPage,
      hasStartPageAccess: response.body.user.acl.startPage.read > 0
    });
  };
}
