/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function loadDataPlans(
  configurationId,
  country,
  oem,
  hardwareVersion
) {
  return async (dispatch) => {
    dispatch({ type: 'LOAD_DATA_PLANS_STARTED' });
    try {
      const requestUrl = `${config.apiServer}/configurations/data-plans`;
      const response = await request.get(requestUrl, {
        query: { configurationId, country, oem, hardwareVersion }
      });
      return dispatch({
        type: 'LOAD_DATA_PLANS_DONE',
        payload: response.body
      });
    } catch (response) {
      dispatch({
        type: 'LOAD_DATA_PLANS_FAILED',
        payload: response.body
      });
    }
  };
}
