/** @format */

import expect from '@edgeguideab/expect';
import isIp from 'is-ip';
import validateIpv6 from './ipv6';
import validateAlarmCenter from './alarmCenter';
import validateCamera from './camera';
import validateInput from './input';
import validateErrorInput from './errorInput';
import validateOutput from './output';
import validateRelay from './relay';
import validateReceiver from './receiver';
import validateRemoteUnit from './remoteUnit';
import validateTestModeSettings from './testModeSettings';
import validateReceiverSettings from './receiverSettings';
import validateContactInformation from './contactInformation';
import validateLan from './lan';
import validateBACnet from './bacnet';

export default function validateConfiguration({
  configuration,
  editConfigurationFeatures,
  country
}) {
  const invoiceReferenceRequired =
    /(dalm.*)\d{3,}/.test(configuration.product) &&
    configuration.simCardType === 'dualtech';
  return expect(
    {
      name: { type: 'string', allowNullErrorCode: 'validation.required.name' },
      invoiceReference: {
        type: 'string',
        errorCode: 'validation.invoice.reference',
        allowNull:
          !invoiceReferenceRequired ||
          !editConfigurationFeatures.includes('extraMetadata'),
        condition: (invoiceReference) =>
          invoiceReferenceRequired ? /\S+/.test(invoiceReference) : true
      },
      product: {
        type: 'string',
        allowNullErrorCode: 'validation.product.notselected'
      },
      productType: {
        type: 'string',
        allowNull: !editConfigurationFeatures.includes('extendedProducts'),
        errorCode: 'validation.product.type.notselected'
      },
      firmwareInterval: {
        type: 'string',
        allowNull:
          !editConfigurationFeatures.includes('extendedProducts') ||
          configuration.productType !== '2G/3G',
        errorCode: 'validation.firmware.interval.notselected'
      },
      folderId: {
        type: 'number',
        parse: true,
        allowNull: true,
        errorCode: 'folder.choose.folder'
      },
      gprsApn: {
        type: 'string',
        errorCode: 'validation.gprsApn.notselected',
        allowNull:
          configuration.product === 'dalmLte' ||
          configuration.simCardType === 'dualtech'
      },
      providerCode: {
        type: 'string',
        errorCode: 'validation.provider.code',
        condition: (providerCode) => /^([0-9]{5,6})$/.test(providerCode),
        allowNull: true
      },
      networkType: 'string',
      ipAddress: {
        type: 'string',
        allowNull: configuration.networkType !== 'networkType.manual',
        condition: (ipAddress) => isIp(ipAddress),
        errorCode: 'validation.ipAddress.notselected'
      },
      netmask: {
        type: 'string',
        allowNull: configuration.networkType !== 'networkType.manual',
        allowNullErrorCode: 'validation.netmask.notselected'
      },
      alarmCenters: {
        type: 'array',
        allowNull: true,
        items: (alarmCenter) =>
          validateAlarmCenter(
            alarmCenter,
            country,
            configuration.productType,
            configuration.firmwareInterval,
            editConfigurationFeatures
          )
      },
      cameras: { type: 'array', allowNull: true, items: validateCamera },
      inputs: {
        type: 'array',
        items: (input) => validateInput({ input, editConfigurationFeatures })
      },
      errorInputs: {
        type: 'array',
        items: () => validateErrorInput(editConfigurationFeatures)
      },
      outputs: {
        type: 'array',
        items: (output) =>
          validateOutput({
            output,
            editConfigurationFeatures,
            product: configuration.product
          })
      },
      outputPassword: {
        type: 'string',
        allowNull: !editConfigurationFeatures.includes('showOutputPassword'),
        errorCode: 'validation.required.installerPassword'
      },
      relays: { type: 'array', items: validateRelay },
      receivers: {
        type: 'array',
        items: (receiver) => validateReceiver({ receiver, configuration })
      },
      remoteUnits: { type: 'array', items: validateRemoteUnit },
      ipv6: validateIpv6(configuration),
      ...validateTestModeSettings(configuration),
      receiverSettings: validateReceiverSettings(configuration),
      contactInformation: validateContactInformation(
        editConfigurationFeatures,
        configuration.contactInformation.invoice,
        country
      ),
      lans: {
        type: 'array',
        items: (lan) => validateLan(lan)
      },
      bacnet: {
        type: 'object',
        allowNull: !editConfigurationFeatures.includes('bacnet'),
        keys: validateBACnet(configuration)
      }
    },
    configuration
  ).errors();
}
